import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/seo'
import HeroSlider from '../components/HeroSlider'
import Location from '../components/Location'
import Section from "../components/Section"
import SliceZone from '../components/SliceZone'
import HomepageTestimonials from "../components/HomepageTestimonials"
import HealthFunds from "../components/HealthFunds"
import {
  PrimaryButton,
  Service,
  TextContent,
  ThreeColumnGrid,
  FourColumnGrid,
  CenterAlign,
  Deal,
  DealMain,
  SecondaryButton,
} from '../styles/utility'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const Homepage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicHomepage.edges[0].node.data
  const testimonialsData = data.allPrismicCustomerTestimonials.edges[0].node.data
  const offersData = data.allPrismicSpecialOffers.edges[0].node.data
  const SeoTitle = RichText.asText(document.meta_title.raw);
  const SeoDescription = RichText.asText(document.meta_description.raw);
  const mainSliderContent = document.main_slider
  console.log(mainSliderContent);
  return (
    <Layout isHomepage>
      <SEO title={SeoTitle} description={SeoDescription} isHomepage noFollow={true} />
      <Helmet>
      <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Dentist",
          "name": "Norwest Dental Care",
          "image": "https://norwestdentalcare.com.au/static/242b851f01b54e8864e5bf506264cea7/cb8c9/ndc-logo.webp",
          "@id": "",
          "url": "https://norwestdentalcare.com.au",
          "telephone": "0288831897",
          "priceRange": "$$",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Suite 118, Level 1, Building B, 20 Lexington Dr",
            "addressLocality": "Bella Vista",
            "addressRegion": "NSW",
            "postalCode": "2153",
            "addressCountry": "AU"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": -33.7351926,
            "longitude": 150.9458239
          },
          "openingHoursSpecification": [{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": "Monday",
            "opens": "09:30",
            "closes": "19:00"
          },{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Tuesday",
              "Thursday",
              "Friday"
            ],
            "opens": "09:30",
            "closes": "17:00"
          },{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": "Wednesday",
            "opens": "10:00",
            "closes": "19:00"
          },{
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": "Saturday",
            "opens": "09:30",
            "closes": "13:00"
          }] 
        }
      `}
      </script>
      </Helmet>
      <HeroSlider slides={mainSliderContent} /> 
      <Section sectionTitle="Our Services">
        <TextContent>
          <p>The services available to patients at Norwest Dental Care are extensive and innovative. We pride ourselves on our ability
      to perform a vast array of Cosmetic Dentistry, General Dentistry and Gum Therapy procedures.</p>
        </TextContent>
        <FourColumnGrid>
          <Service>
            <StaticImage 
              src="../images/undraw_medical_research.png" 
              alt="General Dentistry"
              placeholder="blurred"
              height={175} 
            />
            <div>
              <h3>General Dentistry</h3>
            </div>
          </Service> 
          <Service>
            <StaticImage 
              src="../images/undraw_wash_hands.png" 
              alt="General Dentistry"           
              placeholder="blurred"
              height={175} 
            />
            <div>
              <h3>Gum Therapy</h3>
            </div>
          </Service>
          <Service>
            <StaticImage 
              src="../images/undraw_certification.png" 
              alt="General Dentistry"
              placeholder="blurred"
              objectPosition="50% 50%"
              height={175} 
            />
            <div>
              <h3>Cosmetic Dentistry</h3>
            </div>
          </Service>
          <Service>
            <StaticImage 
              src="../images/undraw_medical_care.png" 
              alt="General Dentistry"
              placeholder="blurred"
              height={175} 
            />
            <div>
              <h3>Dental Surgery</h3>
            </div>
          </Service>
        </FourColumnGrid>
        <CenterAlign>
          <PrimaryButton href="/services/">View all Services</PrimaryButton>
        </CenterAlign>
        </Section>
      <Section sectionTitle="NDC Deals">
        <ThreeColumnGrid>
          {offersData.special_offers.slice(0,3).map((offer, index) => (
            <Deal key={index}>
                <DealMain>
                    <h3>{RichText.asText(offer.offer_price)}</h3>
                    <h3>{RichText.asText(offer.offer_title)}</h3>
                    <p>{RichText.asText(offer.offer_description)}</p>
                    <p>{console.log(offer)}</p>
                </DealMain>
                <SecondaryButton href={offer.offer_link.url ? offer.offer_link.url : '#'}>Claim Offer</SecondaryButton>
            </Deal>
          ))}
        </ThreeColumnGrid>
        <CenterAlign>
          <PrimaryButton href="/special-offers/">View all Deals</PrimaryButton>
        </CenterAlign>
      </Section>
      <HomepageTestimonials data={testimonialsData} />
      <SliceZone sliceZone={document.body} />
      <Section>
        <HealthFunds />
        <Location />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    allPrismicHomepage {
      edges {
        node {
          data {
            meta_description {
              raw
            }
            meta_title {
              raw
            }
            main_slider {
              slide_background {
                url
              }
              slide_button_label {
                text
              }
              slide_button_link {
                url
                target
              }
              slide_description {
                text
              }
              slide_title {
                text
              }
            }
            body {
              ... on PrismicHomepageBodyText {
                slice_type
                primary {
                  content {
                    raw
                  }
                }
              }
              ... on PrismicHomepageBodyQuote {
                slice_type
                primary {
                  quote {
                    raw
                  }
                }
              }
              ... on PrismicHomepageBodyFullWidthImage {
                slice_type
                primary {
                  full_width_image {
                    url
                    thumbnails
                  }
                }
              }
              ... on PrismicHomepageBodyImageGallery {
                slice_type
                primary {
                  gallery_title {
                    raw
                  }
                }
                items {
                  image {
                    url
                    thumbnails
                    alt
                  }
                  image_description {
                    raw
                  }
                  link {
                    url
                    type
                    uid
                  }
                  link_label {
                    raw
                  }
                }
              }
              ... on PrismicHomepageBodyImageHighlight {
                slice_type
                primary {
                  featured_image {
                    url
                    thumbnails
                    alt
                  }
                  title {
                    raw
                  }
                  description {
                    raw
                  }
                  link {
                    url
                    type
                    uid
                  }
                  link_label {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicSpecialOffers {
      edges {
        node {
          data {
            special_offers {
              offer_description {
                text
              }
              offer_title {
                text
              }
              offer_price {
                text
              }
              offer_link {
                url
              }
            }
          }
        }
      }
    }
    allPrismicCustomerTestimonials {
      edges {
        node {
          data {
            long_description {
              text
            }
            testimonials {
              testimonial_description {
                text
              }
              testimonial_name {
                text
              }
            }
            short_description {
              text
            }
          }
        }
      }
    }
  }
`

export default Homepage