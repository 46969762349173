import React from "react";
import Slider from "react-slick";
import { RichText } from 'prismic-reactjs'
import "../../styles/slick/slick.css"; 
import "../../styles/slick/slick-theme.css";
import { Testimonials, TestimonialSlide, TestimonialContent } from "./styles"
import { 
    MaxContainer,
    PrimaryButton,
    Row
  } from "../../styles/utility"

function HomepageTestimonials(props) {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Testimonials>
        <MaxContainer>
            <Row>
              <h2>Customer Testimonials</h2>
              <p>{RichText.asText(props.short_description)}</p>
              <Slider {...settings}>
                {props.data.testimonials.slice(0, 3).map((testimonial, index) => (
                  <TestimonialSlide key={index}>
                      <TestimonialContent>
                          <h3>{RichText.asText(testimonial.testimonial_description)}</h3>
                          <h4>- {RichText.asText(testimonial.testimonial_name)}</h4>
                      </TestimonialContent>
                  </TestimonialSlide>
                  ))}
              </Slider>
              <PrimaryButton href="/testimonials/">View All Testimonials</PrimaryButton>
            </Row>
        </MaxContainer>
    </Testimonials>
  );
}

export default HomepageTestimonials;