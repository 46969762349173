import styled from 'styled-components'
import theme from "../../styles/utility"
import testimonialBackground from "../../images/undraw_personal_opinions.png";

export const Testimonials = styled.div`
    color: ${theme.colors.primaryBlue};
    background: url(${testimonialBackground}) ${theme.colors.secondaryBlue};
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 175px;
    padding-top: 4rem;
    padding-bottom: 5rem;
    margin: 2rem 0;
    text-align: center;
    position: relative;
    a {
        margin-top: 2.5rem;
    }
`;

export const TestimonialSlide = styled.div`

`;

export const TestimonialContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    h3 {
        line-height: 1.8;
        font-size: 16px;
        font-weight: 400;
    }
`;