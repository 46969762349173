import React from "react";
import Slider from "react-slick";
import "../../styles/slick/slick.css"; 
import "../../styles/slick/slick-theme.css";
import { 
    PrimaryButton,
    MaxContainer
  } from "../../styles/utility";
import { Slide, SlideContent, SlideContainer, HeroContainer, SlideBackgroundContainer } from "./styles";

function HeroSlider(props){
  console.log(props);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dotsClass: 'slick-dots hero-dots',
    slidesToScroll: 1
  };

  return (
    <HeroContainer>
      <Slider {...settings}>
      {props.slides.map((slide, index) => (
          <Slide key={index}>
            <SlideBackgroundContainer style={{ backgroundImage: `url(${slide.slide_background.url})` }}>
              <MaxContainer>
                  <SlideContainer>
                      <SlideContent>
                        <h2 className="banner-title">
                          {slide.slide_title.text}
                        </h2>
                        <p className="banner-description">
                          {slide.slide_description.text}
                        </p>
                        <PrimaryButton href={slide.slide_button_link.url} target={slide.slide_button_link.target} className="banner-button">
                          {slide.slide_button_label.text}
                        </PrimaryButton>
                      </SlideContent>
                  </SlideContainer>
              </MaxContainer>
            </SlideBackgroundContainer>
          </Slide>
        ))}
      </Slider>
    </HeroContainer>
  );
}

export default HeroSlider;