import * as React from "react"
import Section from "../Section"
import { 
    FourColumnGrid,
    TextContent,
    MaxImageSize,
  } from "../../styles/utility"
import { StaticImage } from "gatsby-plugin-image"


const HealthFunds = () => (
    <Section>
        <TextContent>
        <h2>We accept all major health funds, including:</h2>
        </TextContent>
        <FourColumnGrid>

            <MaxImageSize>
                <StaticImage 
                    src="../../images/healthfunds/ahm.jpg" 
                    alt="AHM"           
                    placeholder="blurred"
                />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/australian-unity.png" 
                alt="Australian Unity"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/bupa.png" 
                alt="Bupa"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/frank.png" 
                alt="Frank Health Insurance"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/hcf.png" 
                alt="HCF"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/medibank.png" 
                alt="Medibank"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/members-own.png" 
                alt="Members Own"           
                placeholder="blurred"
            />
            </MaxImageSize>
            <MaxImageSize>
            <StaticImage 
                src="../../images/healthfunds/nib.jpg" 
                alt="nib"           
                placeholder="blurred"
            />
            </MaxImageSize>
        </FourColumnGrid>
    </Section>
)


export default HealthFunds
