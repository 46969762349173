import styled from 'styled-components'

export const Slide = styled.div`
    min-height: 500px;
    color: #fff;
    @media all and (min-width: 1024px) {
        min-height: 600px;
    }
`;

export const SlideBackgroundContainer = styled.div`
    background-size: cover;
    background-position: center;
    min-height: 500px;
    color: #fff;
    @media all and (min-width: 1024px) {
        min-height: 600px;
    }
`;

export const SlideContent = styled.div`
    max-width: 100%;
    text-align: center;
    @media all and (min-width: 768px) {
        text-align: left;
    }
    h2 {
        line-height: 1.5;
        font-size: 2rem;
        margin-bottom: 0;
    }
`;

export const HeroContainer = styled.div`
    position: relative;
    overflow: hidden;
`;

export const SlideContainer = styled.div`
    padding: 2rem;
    min-height: 500px;
    display: flex;
    align-items: center;
    @media all and (min-width: 1024px) {
        min-height: 600px;
    }
`;